const en = {
    LANGUAGES: {
        en: "English",
        vn: "Vietnamese"
    },
    MAIN_PAGE: {
        title: "Home",
        description: "Give me your ideas - I will make it come true.",
        titleWhatWeDo: "What we do ?",
        titleDescription:
            "We design websites about all current business areas such as online sales, product display pages, personal blogs, education at all levels, product management websites, import and export, warehouse, etc. selling goods at coffee shops / stalls, milk tea. With our current technology background and experience, we are confident that we will be able to help you design, operate and develop your website.",
        popoverTitle: "hand code - professional - easy to change and maintain as required",
        howWeworkTile: "So, What is developer do ?",
        howWeWorkContent1: "Programmer is also known as Developer (abbreviated as DEV). This is a person who uses programming languages ​​to write, debug and run code to create software and applications that can work on mobile phones, computers, etc.",
        howWeWorkContent2: "Web Programming or Web Developer is a position responsible for receiving all data (static web interface) from the web design department to build a complete website system that interacts with the database and interacts with users. users through computer language. In addition, web programmers can also take on additional tasks such as web administration, support in checking web indicators, maintenance, upgrading features, etc. to make the website work better.",
        howWeWorkContent3: "Programmers can work alone or in a team to complete the final technology product.",
    },
    MENU: {
        profile: "Profile",
        contact: "Contact",
        experience_knowledge: "Experience & Working Place",
        education: "Certification & Personal Awarded"
    },
    PROFILE: {
        workplace: "Work places",
        experience: "Experience years",
        project: "Projects",
        aboutMeTitle: "About me",
        birthDayTitle: "Birth day",
        birthDay: "April 20, 1998",
        addressTitle: "Address",
        address: "Cach Mang Thang 8 street, Ward 15, District 10, Ho Chi Minh city",
        routineTitle: "Routine",
        routine: "Having a cup coffee and listening music on every morning. Sing some classic/ favorite songs 80-90-ZenZ. Travling with friends and family, love to go to quiet places to enjoy their own spaceLikes to go to quiet places to enjoy their own space",
        positionTitle: "Position",
        positionWorking: "Software Developer/ Software Engineer",
        educationTitle: "University",
        education: "Ho Chi Minh University Technology (HUTECH)",
        schoolYearTitle: "School year",
        schoolYear: "2016 - 20219",
        majorTitle: "Major",
        major: "Software Technology",
        startWorkingTitle: "Start Working",
        startWorking: "From May 2019 to now",
        bestCompany: "This is the best workplace, where is used to be worked on.",
        fullstack: "Fullstack Web Developer",
        myName: "Duy Thanh Vo",
        features: "Development participation feature",
        slogant: "Hi... ! I'm Developer",
        subSlogant: "If you are not a genius, practice to become a talented person",
        myImages: "My images",
        myCV: "Click to download my CV",
        totalLessonComplete: "43 subjects",
        totalLessonCompleteTitle: "Total subjects completed",
        typeGraduate: "B+",
        typeGraduateTitle: "Graduation type",
        tabPanelContent1: "The more knowledge you have, the freer you are to trust your instincts.",
        tabPanelContent2: "For me, the meaning of things is never enough. The meaning of life is not simply to exist, to survive, but to move forward, to achieve, to conquer.",
        tabPanelContent3: "Strength does not come from winning. Struggle develops your strengths. When you go through difficulties and decide not to give up, that is strength.",
        tabPanelContent4: "The last three or four reps are what make the muscles grow. This painful area is the boundary between champions and normal people. It's something that most people lack the courage to keep going and say that no matter what, they will get over the pain."
    },
    LANDING: {
        title: "Where Did I Work? And What Did I Do There?",
        description: "During more than 4 years working at 3 different workplaces, I have learned a lot of knowledge and skills (including technical and soft skills), met many people, learned and improved my knowledge. to improve myself. It was possibly the most meaningful time in my life to date.",
        from: "From:",
        to: "To:",
        position:"Position:",
        workingAddress: "Working address:",
        startedHere: "My journey starts from here",
        feeling: "I feel really lucky and happy to work with great people like these, people whose energy is eternal, they are willing to share it, convey it and Apply it to those who truly need it to thrive",
        great: "Great...!!!",
        technologies: "Technologies we used",

        WORKING_PLACE:{
            FPT:{
                from:"July 2019",
                to: "July 2020",
                responsibility: "Attending into project's development progressing to get knowledge, experience, improve and practice personal skills. Daily meeting and teamwork to discuss/raise up the risks that can be encountered in developing the project. Base on that to know, learn, get experience and self-improve.",
                address: "F-Town 1&2 Tower, Lot T2, D1 Street, Tan Phu Ward, District 9, Ho Chi Minh city",

                INTERN:{
                    from:"May 2019",
                    to: "July 2019",
                    responsibility: "Participate in traineeship and FRESHER training course. Trained in knowledge and skills to handle situations in real projects. Develop simulation projects according to lecturers' ideas. Report and get reviews every weekend and plan for the next week.",
                    address: "F-Town 1&2 Tower, Lot T2, D1 street, D1 Street, Tan Phu Ward, District 9, Ho Chi Minh city",
                },

                COMEBACK: {
                    from:"June 2024",
                    to: "Current",
                    responsibility: "Participate in 2 key projects of the company under the coordination and management of superiors. Handle APIs according to requirements documents and work according to Scrum/Alige workflow. Work directly with customers and participate in pre-planned weekly meetings.",
                    address: "F-Town 3 Tower, Lot E3, Vo Chi Cong Street, Long Thanh My Ward, Thu Duc District, Ho Chi Minh District",
                }
            },
            CODE88:{
                from:"August 2020",
                to: "July 2022",
                responsibility: "Develop features and maintain the system based on the specification document provided and analized from SA. Coordinate with team leader and QC team to discuss and prevent some possible risks.",
                address: "Floor 6th, VietJet Tower, Ward 02, Tân Bình District, Ho Chi Minh city"
            },
            MEDIA_STEP:{
                from:"July 2022",
                to: "June 2024",
                responsibility: "Develop features and maintain system with hight performance. Coordinate with manager, product owner, team leader. QC's member team to discuss and prevent some possible risks. Operate and run code in many different environments during website development using Jenkin tool (specialized for coding code and deploying code in the cloud environment).",
                address: "Floor 12, VietJet Tower, Ward 02, Tân Bình District, Ho Chi Minh city"
            }
        },
    },
    LANDING2 : {
        iHad: "Here,... I got",
        certificate: "Certificate",
        awarded: "Awarded",
        description: "4 years is a relatively memorable period for me. It gave me more knowledge and more energy to face challenges and new paths in the future. Motivates me to constantly strive to learn and improve myself more.",
        description2: "After a period of working hard and spending most of my time on work, I also spend part of my time participating in sports activities to train my body and cultural activities. - Arts (singing, dancing, dancing...) that the company organizes. Contribute a small part of your value to the collective activities of the company where I working."
    },
    DOWNLOAD_SECTION:{
        inviteTitle: "Do you want to own your own Website? Do you need a Dynamic Web or a Static Web ?",
        invite: "Design and process features according to requirements and descriptions/drawings/designs that are clearly defined and detailed in writing. Handles Web features both on the user interface side and on the services that run underground under the Web system. Manage and design appropriate databases for each feature. Discuss and give opinions to prevent possible risks to the system.",
        objective: "Objective",
        objectiveTitle:"Take advantages of coding skills, experience of a web application engineer to bring a lot of possible values to company. Explore and never stop learning. Desire to be learn newest technologies now. Based on that, can be improved and progressed up to the higher level at work.",
        canUse: "I can use",
        joined: "Typical projects used to be worked on"
    },
    PROJECTS:{
      GOSELL: "GoSELL's e-commerce sales management feature is a system of tools that help businesses or small stores easily manage detailed business segments and provide quick sales services. , accurately so that customers are always satisfied with the business and store.",
      PINPAY: "Admin Web application on the admin side. Used to manage all transactions and cash flows of players around the world. Linking with online payment portals of banks/e-wallets of countries around the world to support the deposit/withdrawal process is fast, safe and secure.",
      KARSIKORN_BANK:"Web application manages transactions on the bank administrator side. Manage users and transactions of foreign currency merchants, internal transactions of domestic customers, manage periodic deposits and interest rates.",
      SENMONTEN:"SENMONTEN - Web application to manage products, employees, orders, invoices, warehouse receipts, and suppliers for internal Japanese cosmetics corporation - Shiseido with all branches nationwide in That includes Vietnam."
    },
    FOOTER:{
        designedBy: "Designed by"
    }
}

export default en;