<template>
  <div>
    <div class="page-header clear-filter" filter-color="orange">
      <parallax class="page-header-image" style="background-image: url('img/bg5.jpg')">
      </parallax>
      <div class="container">
        <div class="photo-container">
          <img src="img/avtgosell.jpg"/>
        </div>
        <h3 class="title">Oscar Vo</h3>
        <h4 class="title">{{ $t('PROFILE.myName') }}</h4>
        <p class="category">{{ $t('PROFILE.fullstack') }}</p>
        <div class="content">
          <div class="social-description">
            <h2>3+</h2>
            <p>{{ $t("PROFILE.workplace") }}</p>
          </div>
          <div class="social-description">
            <h2>5+</h2>
            <p>{{ $t("PROFILE.experience") }}</p>
          </div>
          <div class="social-description">
            <h2>12+</h2>
            <p>{{ $t("PROFILE.project") }}</p>
          </div>
          <div class="social-description">
            <h2>122+</h2>
            <p>{{ $t("PROFILE.features") }}</p>
          </div>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="container">
        <div class="button-container">
          <a
              href="https://www.facebook.com/oscar.vo.1998/"
              class="btn btn-default btn-round btn-lg btn-icon"
              rel="tooltip"
              title="Follow me on facebook"
          >
            <i class="fab fa-facebook"></i>
          </a>
          <a
              href="https://www.instagram.com/duy.thanh.vo/"
              class="btn btn-default btn-round btn-lg btn-icon"
              rel="tooltip"
              title="Follow me on Instagram"
          >
            <i class="fab fa-instagram"></i>
          </a>
        </div>
        <h3 class="title">{{ $t('PROFILE.aboutMeTitle') }}</h3>
        <card :plain="true">
          <div class="row mb-5">
            <div class="col-sm-3 ml-auto mr-auto fw-700"><i class="now-ui-icons users_single-02"></i>
              {{ $t('PROFILE.birthDayTitle') }}
            </div>
            <div class="col ml-auto mr-auto">
              {{ $t('PROFILE.birthDay') }}
            </div>
          </div>
          <div class="row mb-5">
            <div class="col-sm-3 ml-auto mr-auto"><i class="now-ui-icons location_map-big"></i>
              {{ $t('PROFILE.addressTitle') }}
            </div>
            <div class="col ml-auto mr-auto ml-auto mr-auto">
              {{ $t('PROFILE.address') }}
            </div>
          </div>
          <div class="row mb-5">
            <div class="col-sm-3 ml-auto mr-auto"><i class="now-ui-icons objects_diamond"></i>
              {{ $t('PROFILE.routineTitle') }}
            </div>
            <div class="col ml-auto mr-auto">
              {{ $t('PROFILE.routine') }}
            </div>
          </div>
          <div class="row mb-5">
            <div class="col-sm-3 ml-auto mr-auto"><i class="now-ui-icons education_hat"></i>
              {{ $t('PROFILE.educationTitle') }}
            </div>
            <div class="col ml-auto mr-auto">
              {{ $t('PROFILE.education') }}
            </div>
          </div>
          <div class="row mb-5">
            <div class="col-sm-3 ml-auto mr-auto"><i class="now-ui-icons ui-2_time-alarm"></i>
              {{ $t('PROFILE.schoolYearTitle') }}
            </div>
            <div class="col ml-auto mr-auto">
              {{ $t('PROFILE.schoolYear') }}
            </div>
          </div>
          <div class="row mb-5">
            <div class="col-sm-3 ml-auto mr-auto"><i class="now-ui-icons design-2_ruler-pencil"></i>
              {{ $t('PROFILE.totalLessonCompleteTitle') }}
            </div>
            <div class="col ml-auto mr-auto">
              {{ $t('PROFILE.totalLessonComplete') }}
            </div>
          </div>
          <div class="row mb-5">
            <div class="col-sm-3 ml-auto mr-auto"><i class="now-ui-icons education_paper"></i>
              {{ $t('PROFILE.typeGraduateTitle') }}
            </div>
            <div class="col ml-auto mr-auto">
              {{ $t('PROFILE.typeGraduate') }}
            </div>
          </div>
          <div class="row mb-5">
            <div class="col-sm-3 ml-auto mr-auto"><i class="now-ui-icons education_atom"></i>
              {{ $t('PROFILE.majorTitle') }}
            </div>
            <div class="col ml-auto mr-auto">
              {{ $t('PROFILE.major') }}
            </div>
          </div>
          <div class="row mb-5">
            <div class="col-sm-3 ml-auto mr-auto"><i class="now-ui-icons tech_laptop"></i>
              {{ $t('PROFILE.positionTitle') }}
            </div>
            <div class="col ml-auto mr-auto">
              {{ $t('PROFILE.positionWorking') }}
            </div>
          </div>
          <div class="row mb-5">
            <div class="col-sm-3 ml-auto mr-auto"><i class="now-ui-icons ui-1_calendar-60"></i>
              {{ $t('PROFILE.startWorkingTitle') }}
            </div>
            <div class="col ml-auto mr-auto">
              {{ $t('PROFILE.startWorking') }}
            </div>
          </div>
          <div class="row mb-5">
            <div class="col-sm-3 ml-auto mr-auto"></div>
            <div class="col ml-auto mr-auto">
              <!--              <n-button type="primary" round @click="downloadFile">-->
              <!--                <i class="now-ui-icons arrows-1_share-66"></i> {{ $t('PROFILE.myCV') }}-->
              <!--              </n-button>-->
              <a :href="'/files/[CV][VoThanhDuy]-Java Web Engineer.pdf'"
                 download="[CV][VoThanhDuy]-Java Web Engineer.pdf"><i
                  class="now-ui-icons arrows-1_cloud-download-93 mr-3"></i> {{ $t('PROFILE.myCV') }}</a>
            </div>
          </div>
        </card>
        <div class="row ml-auto mr-auto mt-3 mb-3">
        </div>
        <div class="row">
          <div class="col-md-6 ml-auto mr-auto">
            <h4 class="title text-center">{{ $t('PROFILE.myImages') }}</h4>
          </div>
          <tabs
              pills
              class="nav-align-center"
              tab-content-classes="gallery"
              tab-nav-classes="nav-pills-just-icons"
              type="primary"
          >
            <tab-pane>
              <i slot="label" class="now-ui-icons design_image"></i>
              <div class="col-md-10 ml-auto mr-auto">
                <p class="text-black text_bold title-panel">"{{$t('PROFILE.tabPanelContent1')}}"</p>
                <div class="row collections">
                  <div class="col-md-6">
                    <img src="my-img/p-2.jpg" class="img-raised"/>
                  </div>
                  <div class="col-md-6">
                    <img src="my-img/p-1.jpg" class="img-raised"/>
                  </div>
                  <div class="col-md-6">
                    <img src="my-img/code88selffee.jpg" class="img-raised"/>
                  </div>
                  <div class="col-md-6">
                    <img src="my-img/travelnewhouse.jpg" class="img-raised"/>
                  </div>
                </div>
              </div>
            </tab-pane>

            <tab-pane title="Friends and memories">
              <i slot="label" class="now-ui-icons transportation_air-baloon"></i>
              <div class="col-md-10 ml-auto mr-auto">
                <p class="text-black text_bold title-panel">"{{$t('PROFILE.tabPanelContent2')}}"</p>
                <div class="row collections">
                  <div class="col-md-6">
                    <img src="my-img/team-building-1.jpg" class="img-raised"/>
                    <img src="my-img/team-building-2.jpg" class="img-raised"/>
                  </div>
                  <div class="col-md-6">
                    <img src="my-img/team-building-3.jpg" class="img-raised"/>
                    <img src="my-img/team-building-4.jpg" class="img-raised"/>
                  </div>
                </div>
              </div>
            </tab-pane>

            <tab-pane title="Out side activity">
              <i slot="label" class="now-ui-icons emoticons_satisfied"></i>
              <div class="col-md-10 ml-auto mr-auto">
                <div class="row collections">
                  <p class="text-black text_bold title-panel">"{{$t('PROFILE.tabPanelContent3')}}"</p>
                  <div class="col-md-6">
                    <img src="my-img/sanbong.jpg" class="img-raised"/>
                    <img src="my-img/avt.jpg" class="img-raised"/>
                  </div>
                  <div class="col-md-6">
                    <img src="my-img/withtruongvu.jpg" class="img-raised"/>
                    <img src="my-img/coffeeshop.jpg" class="img-raised"/>
                  </div>
                  <div class="col-md-6">
                    <img src="my-img/halongtowner.jpg" class="img-raised"/>
                  </div>
                </div>
              </div>
            </tab-pane>
            <tab-pane title="Gym for me">
              <p class="text-black text_bold title-panel">"{{$t('PROFILE.tabPanelContent4')}}"</p>
              <i slot="label" class="now-ui-icons sport_trophy"></i>
              <div class="row collections">
                <div class="col-md-6">
                  <img src="my-img/gym2.png" class="img-raised"/>
                  <img src="my-img/gym4.png" class="img-raised"/>
                </div>
                <div class="col-md-6">
                  <img src="my-img/gym1.png" class="img-raised"/>
                  <img src="my-img/gym3.png" class="img-raised"/>
                </div>
              </div>
            </tab-pane>
          </tabs>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {TabPane, Tabs} from "@/components";
import Card from "../components/Cards/Card.vue";
import Landing from "./Landing.vue";
import NButton from "@/components/Button.vue";

export default {
  name: "profile",
  bodyClass: "profile-page",
  components: {
    NButton,
    Landing,
    Card,
    Tabs,
    TabPane,
  },

  data() {
    return {
      file: {
        cv: require("../assets/files/[CV][VoThanhDuy]-Java Web Engineer.pdf")
      },
    }
  },

  methods: {
    // downloadFile() {
    //   // create element <a> for download PDF
    //   const link = document.createElement('a');
    //   link.href = '../assets/files/[CV][VoThanhDuy]-Java Web Engineer.pdf';
    //   link.target = '_blank';
    //   link.download = '[CV][VoThanhDuy]-Java Web Engineer.pdf';
    //
    //   // Simulate a click on the element <a>
    //   document.body.appendChild(link);
    //   link.click();
    //   console.log(link)
    //   document.body.removeChild(link);
    // },
  }
}

</script>

<style>
  p.title-panel {
    font-style: 18px !important;
    font-family: monospace !important;
  }
</style>