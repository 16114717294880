var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.tag,{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeDropDown),expression:"closeDropDown"}],tag:"component",staticClass:"dropdown",class:[
    { show: _vm.isOpen },
    { dropdown: _vm.direction === 'down' },
    { dropup: _vm.direction === 'up' }
  ],attrs:{"aria-haspopup":"true","aria-expanded":_vm.isOpen},on:{"click":_vm.toggleDropDown}},[_vm._t("title",function(){return [_c('a',{staticClass:"dropdown-toggle nav-link",class:{ 'no-caret': _vm.hideArrow },attrs:{"data-toggle":"dropdown"}},[_c('i',{class:_vm.icon}),_c('span',{staticClass:"no-icon"},[_vm._v(_vm._s(_vm.$t('LANGUAGES.' + `${_vm.selected}`)))])])]}),_c('ul',{staticClass:"dropdown-menu",class:[
      { 'dropdown-menu-right': _vm.position === 'right' },
      { show: _vm.isOpen }
    ]},[_vm._t("default"),_vm._l((_vm.languages),function(item){return [_c('div',{key:item.code,staticClass:"d-inline d-block"},[_c('li',{staticClass:"dropdown-item",on:{"click":function($event){return _vm.onSelectLanguage(item)}}},[(item.image)?_c('img',{staticClass:"mr-2",attrs:{"src":item.image}}):_vm._e(),_vm._v(_vm._s(item.value)+" ")])])]})],2)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }