<template>
  <div
      class="section section-download"
      id="#example-section"
      data-background-color="black"
  >
    <div class="container">
      <div class="row justify-content-md-center sharing-area text-center">
        <div class="text-center col-md-12 col-lg-8">
          <h2>{{$t('DOWNLOAD_SECTION.joined')}}</h2>
        </div>
        <div class="text-center col-md-12 col-lg-8">
          <div class="row">
            <template v-for="pr in projects">
              <div :key= "pr.id" class="col-md-4">
                <div class="card-header">
                  <div class="img-container">
                    <img :src="pr.logo" class="img-fluid" style="height: 80px; width: 100px">
                  </div>
                </div>
                <div class="card-body">
                 <p class="description text_align-left">{{pr.description}}</p>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Card from "@/components/Cards/CertificateCard.vue";

export default {
  components: {Card},

  data(){
    return {
      projects: [
        {
          id: "001",
          logo: "img/gosell.jpg",
          description: this.$i18n.t('PROJECTS.GOSELL')
        },
        {
          id: "002",
          logo: "img/merchant-login-logo.png",
          description: this.$i18n.t('PROJECTS.PINPAY')
        },
        {
          id: "003",
          logo: "img/kasikor_bank.png",
          description: this.$i18n.t('PROJECTS.KARSIKORN_BANK')
        },
        {
          id: "004",
          logo: "img/Shiseido.jpg",
          description: this.$i18n.t('PROJECTS.SENMONTEN')
        }
      ]
    }
  }
};
</script>
<style>
</style>

