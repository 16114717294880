<template>
  <div class="section">
    <div class="container text-center">
      <div class="row justify-content-md-center">
        <div class="col-md-12 col-lg-8">
          <h2 class="title">{{ $t("MAIN_PAGE.howWeworkTile") }}</h2>
          <h5 class="blockquote blockquote-footer">
            {{ $t("MAIN_PAGE.howWeWorkContent1") }}
          </h5>
          <h5 class="blockquote blockquote-danger blockquote-footer">
            {{ $t("MAIN_PAGE.howWeWorkContent2") }}
          </h5>
          <div class="typography-line">
            <blockquote>
            <p class="blockquote blockquote-footer">
              {{ $t("MAIN_PAGE.howWeWorkContent3") }}
            </p>
            </blockquote>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {};
</script>
