const vn = {
    LANGUAGES: {
        en: "Tiếng Anh",
        vn: "Tiếng Việt"
    },
    MAIN_PAGE: {
        title: "Trang chủ",
        description: "Trao cho tôi ý tưởng của bạn - Tôi sẽ làm nó thành sự thật",
        titleWhatWeDo: "Chúng tôi làm về ?",
        titleDescription:
            "Chúng tôi thiết kế website về tất cả các lĩnh vực kinh doanh hiện nay như bán hàng online, các trang trưng bày sản phẩm, blog cá nhân, giáo dục ở tất các cấp, các trang web quản lý sản phẩm, xuất nhập kho,  bán hàng ở các quán/ quầy cà phê, trà sữa. Với các nền tảng công nghệ và kinh nghiệm hiện mà chúng tôi đang có, chúng tôi tự tin rằng chúng tôi sẽ có thể giúp bạn thiết kế, vân hành và phát triển website của bạn.",
        popoverTitle: "code tay - chuyên nghiệp - dễ thay đổi và bảo trì theo yêu cầu",
        howWeworkTile: "Vậy Lập Trình Viên Là Làm Gì ?",
        howWeWorkContent1: "Lập trình viên (tiếng anh là Programmer) hay còn được gọi là Nhà phát triển (Developer – viết tắt là DEV). Đây là người sử dụng các ngôn ngữ lập trình để viết, sửa lỗi và cho chạy các đoạn mã nhằm tạo ra các phần mềm, ứng dụng có thể hoạt động trên các thiết bị điện thoại di động, máy tính,...",
        howWeWorkContent2: "Lập trình web hay Web Developer là vị trí có nhiệm vụ nhận toàn bộ dữ liệu (giao diện web tĩnh) từ bộ phận thiết kế web để xây dựng một hệ thống website hoàn chỉnh có tương tác với cơ sở dữ liệu và tương tác với người dùng thông qua ngôn ngữ máy tính. Ngoài ra, nhân viên lập trình web cũng có thể đảm nhận thêm những nhiệm vụ như quản trị web, hỗ trợ kiểm tra các chỉ số web, bảo trì, nâng cấp các tính năng,... để website hoạt động tốt hơn.",
        howWeWorkContent3: "Lập trình viên có thể làm việc một mình hoặc làm việc theo một nhóm để hoàn thiện sản phẩm công nghệ đến cuối cùng.",
    },
    MENU: {
        profile: "Thông tin cá nhân",
        contact: "Liên hệ",
        experience_knowledge: "Kinh nghiệm & Nơi làm việc",
        education: "Chứng chỉ & Giải thưởng cá nhân"
    },
    PROFILE: {
        workplace: "Nơi làm việc",
        experience: "Năm kinh nghiệm",
        project: "Dự án tham gia",
        aboutMeTitle: "Chút thông tin về tôi",
        birthDayTitle: "Ngày sinh",
        birthDay: "20/04/1998",
        addressTitle: "Địa chỉ",
        address: "Cách Mạng Tháng 8, Phường 15, Quận 10, TP.HCM",
        routineTitle: "Thói quem/ Sở thích",
        routine: "Thưởng thức 1 ly cà phê và nghe nhạc vào mỗi buổi sáng. Hát một vài ca khúc cổ điển/ ưa chuộng 80-90-ZenZ. Du lịch với bạn bè và người thân, thích đi đến những nơi yên tĩnh để tận hưởng không gian của riêng mình",
        positionTitle: "Chức vụ",
        positionWorking: "Nhà phát triển phẩn mềm/ Kỹ sư phần mềm",
        educationTitle: "Trường",
        education: "Đại Học Công Nghệ Thành Phố Hồ Chí Minh (HUTECH)",
        schoolYearTitle: "Niên khóa",
        schoolYear: "2016 - 20219",
        majorTitle: "Chuyên ngành",
        major: "Công Nghệ Phần Mềm",
        startWorkingTitle: "Bắt đầu làm việc",
        startWorking: "Từ tháng 05/2019 đến nay",
        bestCompany: "Đây là nơi làm việc tốt nhất mà tôi đã từng.",
        fullstack: "Nhà phát triển Web - Fullstack",
        myName: "Võ Thanh Duy",
        features: "Tính năng tham gia phát triển",
        slogant: "Xin chào... ! Tôi là một lập trình viên",
        subSlogant: "Nếu không phải thiên tài, bạn hãy rèn luyện để trở thành một nhân tài",
        myImages: "Chút hình ảnh về tôi",
        myCV: "Bấm để tải CV của tôi",
        totalLessonComplete: "43 môn",
        totalLessonCompleteTitle: "Tổng số môn học đã hoàn thành",
        typeGraduate: "B+",
        typeGraduateTitle: "Loại tốt nghiệp",
        tabPanelContent1: "Càng có nhiều kiến thức, bạn càng tự do trong việc tin cậy vào bản năng của mình.",
        tabPanelContent2: "Đối với tôi ý nghĩa mọi thứ không bao giờ là đủ. Ý nghĩa cuộc sống không chỉ đơn giản là tồn tại, là sống sót, mà phải là tiến lên, để đạt được, để chinh phục.",
        tabPanelContent3: "Sức mạnh không đến từ chiến thắng. Sự đấu tranh phát triển thế mạnh của bạn. Khi bạn trải qua khó khăn và quyết định không đầu hàng, đó là sức mạnh.",
        tabPanelContent4: "Ba hoặc bốn reps cuối cùng là thứ làm cho cơ bắp phát triển. Khu vực đau đớn này là ranh giới giữa nhà vô địch và người bình thường. Đó là thứ mà hầu hết mọi người thiếu sự can đảm để tiếp tục và nói rằng dù thế nào đi chăng nữa, họ cũng sẽ vượt qua nỗi đau."
    },
    LANDING: {
        title: "Tôi Đã Làm Việc Ở Đâu ? Và Tôi Đã làm gì ở đó",
        description: "Trong suốt hơn 4 năm làm việc tại 3 nơi làm việc khác nhau, tôi đã học hỏi được nhiều kiến thức, kỹ năng (bao gồm kỹ thuật và kỹ năng mềm), gặp gỡ được nhiều người, học hỏi và trau dồi kiến thức để cải thiện bản thân mình. Đó có thể là khoản thời gian ý nghĩa nhất trong cuộc đời tôi cho đến thời điểm hiện tại.",
        from: "Từ:",
        to: "Đến:",
        position:"Chức vụ/ Vị trí:",
        workingAddress: "Địa chỉ làm việc:",
        startedHere: "Hành trình của tôi bắt đầu từ đây",
        feeling: "Tôi cảm thấy thật sự rất may mắn và hạnh phúc khi được làm việc cùng với những con người tuyệt vời như thế này, những con người mà nguồn năng lượng của họ là bất diệt, họ sẵn sàng san sẻ nó, truyền đạt nó và áp dụng nó cho những người thật sự cần để phát triển",
        great: "Thật tuyệt vời...!!!!",
        technologies: "Công nghệ chúng tôi sử dụng",

        WORKING_PLACE:{
            FPT:{
                from:"Tháng 05, 2019",
                to: "Tháng 07, 2020",
                responsibility: "Tham gia vào quá trình phát triển của dự án để có được kiến thức, kinh nghiệm, nâng cao và rèn luyện các kỹ năng cá nhân. Họp và làm việc nhóm hàng ngày để thảo luận/nâng cao những rủi ro có thể gặp phải trong quá trình phát triển dự án. Dựa vào đó để biết, học hỏi, rút kinh nghiệm và hoàn thiện bản thân.",
                address: "Tòa nhà F-Town 1&2, Lô T2, Đường D1, Đ. D1, Phường Tân Phú, Quận 9, Thành phố Hồ Chí Minh",

                INTERN:{
                    from:"Tháng 05, 2019",
                    to: "Tháng 07, 2019",
                    responsibility: "Tham gia vào khóa thực tập sinh và khóa đào tạo FRESHER. Được đào tạo về kiến thức, kỹ năng xử lý tình huống trong các dự án thật. Phát triển dự án giả lập theo ý tưởng của giảng viên. Báo cáo và nhận đánh giá mỗi cuối tuần và lên kế hoạch cho tuần tiếp theo.",
                    address: "Tòa nhà F-Town1&2, Lô T2, Đường D1, Đ. D1, Phường Tân Phú, Quận 9, Thành phố Hồ Chí Minh",
                },

                COMEBACK: {
                    from:"Tháng 06, 2024",
                    to: "Hiện tại",
                    responsibility: "Tham gia vào 2 dự án trọng điểm của công ty dưới sự điều phối và quản lý của cấp trên. Xử lý các API theo tài liệu yêu cầu và làm việc theo qui trình làm việc Scrum/Alige. Làm việc trực tiếp với khách hàng và tham gia các cuộc họp định kỳ hàng tuần có kế hoạch từ trước.",
                    address: "Tòa nhà F-Town 3, Lô E3, Đ. Võ Chí Công, Phường Long Thạnh Mỹ, Quận Thủ Đức, Thành phố Hồ Chí Minh",
                }
            },
            CODE88:{
                from:"Tháng 08, 2020",
                to: "Tháng 07, 2022",
                responsibility: "Phát triển các tính năng và bảo trì hệ thống dựa trên tài liệu đặc tả được cung cấp và phân tích từ SA. Phối hợp với trưởng nhóm và nhóm QC để thảo luận và ngăn ngừa một số rủi ro có thể xảy ra.",
                address: "Tầng 6, Tòa Nhà VietJet, Phường 02, Quận Tân Bình, TP.HCM"
            },
            MEDIA_STEP:{
                from:"Tháng 07, 2022",
                to: "Tháng 06, 2024",
                responsibility: "Phát triển các tính năng và duy trì hệ thống với hiệu suất cao. Phối hợp với người quản lý, chủ sở hữu sản phẩm, trưởng nhóm. Nhóm thành viên QC cùng thảo luận và ngăn ngừa một số rủi ro có thể xảy ra. Vận hành và chạy các mã code ở nhiều môi trường khác nhau trong quá trình phát triển website bằng công cụ Jenkin (chuyên dùng để mã hóa code và triển khai code trên môi trường cloud)",
                address: "Tầng 12, Tòa Nhà VietJet, Phường 02, Quận Tân Bình, TP.HCM"
            }
        }
    },
    LANDING2 : {
        iHad: "Đây,... là những gì tôi đã...",
        certificate: "Chứng nhận/ Chứng chỉ",
        awarded: "Giải thưởng/ Danh hiệu",
        description: "4 năm, là một khoảng thời gian tương đối nhiều kỉ niệm đối với tôi, nó cho tôi được nhiều kiến thức, nhiều năng lượng hơn để đối diện với những thử thách, những chặn đường mới trong tương lai. Thúc đẩy tôi không ngừng nổ lực học tập vào và cải thiện bản thân mình nhiều hơn.",
        description2: "Sau khoảng thời gian làm việc hăng say và giành phần lớn thời gian của mình cho công việc, thì bên cạnh đó tôi cũng giành một phần thời gian để tham gia các hoạt động thể thao để rèn luyện thân thể và các hoạt động Văn Hóa - Nghệ Thuật (hát, nhảy, múa...) mà công ty tổ chức. Đóng góp một phần giá trị nhỏ của bản thân vào các hoạt động tập thể của công ty, nơi mình đang làm việc."
    },
    DOWNLOAD_SECTION: {
        inviteTitle: "Tôi Có Thể Làm Được Gì ?",
        invite: "Thiết kế và xử lý các tính năng theo yêu cầu và mô tả/ bản vẽ/ bản thiết kế được xác định rõ ràng cụ thể chi tiết bằng văn bản. Xử lý các tính năng của Web cả bên phía giao diện người dùng lẫn cả phần services chạy ngầm bên dưới hệ thống của Web. Quản lý và thiết kế cơ sở dữ liệu phù hợp cho từng tính năng. Thảo luận và đưa ra ý kiến để ngăn ngừa rủi ro có thể xảy ra cho hệ thống.",
        objective: "Mục Tiêu Nghề Nghiệp",
        objectiveTitle: "Tận dụng kỹ năng code, kinh nghiệm của một kỹ sư ứng dụng web để mang lại nhiều giá trị có thể có cho công ty. Khám phá và không ngừng học hỏi. Mong muốn được học hỏi những công nghệ mới nhất hiện nay. Dựa vào đó có thể hoàn thiện và thăng tiến lên cấp cao hơn trong công việc.",
        canUse: "Tôi có thể sử dụng",
        joined: "Các dự án tiêu biểu từng tham gia"
    },
    PROJECTS:{
        GOSELL: "Tính năng quản lý bán hàng thương mại điện tử (TMĐT) của GoSELL là hệ thống các công cụ giúp cho doanh nghiệp hoặc cửa hàng nhỏ dễ dàng quản lý chi tiết các phân đoạn trong kinh doanh và cung cấp dịch vụ bán hàng nhanh chóng, chính xác để khách hàng luôn hài lòng với doanh nghiệp, cửa hàng.",
        PINPAY: "Ứng dụng Web quản trị bên phía quản trị viên. Dùng để quản lý tất cả các giao dịch, dòng tiền của người chơi trên toàn thế giới. Liên kết với cổng thanh toán trực tuyến của các ngân hàng/ ví điện tử của các quốc gia trên toàn thế giới nhầm hỗ trợ quá trình nạp/ rút tiền diễn ra được nhanh chóng, an toàn và bảo mật.",
        KARSIKORN_BANK:"Ứng dụng Web quản lý các giao dịch bên phía quản trị viên của ngân hàng. Quản lý người dùng và các giao dịch của các thương gia mua bán ngoại tệ, các giao dịch nội bộ của khách hàng trong nước, quản lý khoản vây và lãi xuất định kỳ.",
        SENMONTEN:"SENMONTEN - Ứng dụng Web quản lý sản phẩm, nhân viên, đơn hàng, hóa đơn, phiếu nhập xuất kho, nhà cung cấp cho nội bộ của tập đoàn mỹ phẩm của Nhật Bản - Shiseido với tất cả các chi nhánh trên toàn quốc trong đó có Việt Nam."
    },
    FOOTER: {
        designedBy: "Thiết kế bởi"
    }
}
export default vn;