<template>
  <component
      class="dropdown"
      :is="tag"
      :class="[
      { show: isOpen },
      { dropdown: direction === 'down' },
      { dropup: direction === 'up' }
    ]"
      aria-haspopup="true"
      :aria-expanded="isOpen"
      @click="toggleDropDown"
      v-click-outside="closeDropDown"
  >
    <slot name="title">
      <a
          class="dropdown-toggle nav-link"
          :class="{ 'no-caret': hideArrow }"
          data-toggle="dropdown"
      >
        <i :class="icon"></i>
        <span class="no-icon">{{ $t('LANGUAGES.' + `${selected}`) }}</span>
      </a>
    </slot>
    <ul
        class="dropdown-menu"
        :class="[
        { 'dropdown-menu-right': position === 'right' },
        { show: isOpen }
      ]"
    >
      <slot></slot>
      <template v-for="item in languages">
        <div :key="item.code" class="d-inline d-block">
          <li class="dropdown-item" v-on:click="onSelectLanguage(item)">
            <img class="mr-2" v-if="item.image" :src="item.image"/>{{ item.value }}
          </li>
        </div>
      </template>
    </ul>
  </component>
</template>
<script>
export default {
  name: 'drop-down',
  props: {
    direction: {
      type: String,
      default: 'down'
    },
    title: String,
    icon: String,
    position: String,
    hideArrow: Boolean,
    tag: {
      type: String,
      default: 'li'
    },
    items: [],
    onSelect: Function
  },
  data() {
    return {
      isOpen: false,
      selected: this.$i18n.locale,
      languages: [
        {
          image: "img/flags/US.png",
          langKey: "en",
          value: this.$i18n.t('LANGUAGES.en')
        },
        {
          image: "img/flags/VN.png",
          langKey: "vn",
          value: this.$i18n.t('LANGUAGES.vn')
        }
      ],
    };
  },
  provide() {
    return {
      closeDropDown: this.closeDropDown
    }
  },
  methods: {
    toggleDropDown() {
      this.isOpen = !this.isOpen;
      this.$emit('change', this.isOpen);
    },
    closeDropDown() {
      this.isOpen = false;
      this.$emit('change', this.isOpen);
    },

    onSelectLanguage(lang) {
      this.selected = lang?.langKey || 'vn'
      this.$root.$i18n.locale = lang?.langKey || 'vn';
      this.languages = [];
      this.languages.push({
            image: "img/flags/US.png",
            langKey: "en",
            value: this.$i18n.t('LANGUAGES.en')
          },
          {
            image: "img/flags/VN.png",
            langKey: "vn",
            value: this.$i18n.t('LANGUAGES.vn')
          })
      localStorage.setItem("APP_LANGUAGE", lang?.langKey);
      location.reload()
    }
  }
};
</script>
<style>
.dropdown {
  list-style-type: none;
}

.dropdown .dropdown-toggle {
  cursor: pointer;
}

.dropdown-item:hover {
  cursor: pointer;
}
</style>
