<template>
  <div
      :id="id"
      class="card"
      :class="[
      { 'card-plain': plain },
      { 'card-raised': raised },
      { [`card-${type}`]: type },
    ]"
      :data-background-color="color"
      style="padding-left: 5px; padding-right: 5px"
  >
    <h6 class="card-category" v-if="$slots.category || category">
      {{ category }}
    </h6>
    <div class="card-image" v-if="$slots.image">
      <slot name="image"></slot>
    </div>

    <div class="card-avatar" v-if="$slots.avatar">
      <slot name="avatar"></slot>
    </div>

    <div class="info" v-if="$slots.info">
      <slot name="info"></slot>
    </div>

    <div class="card-header" :class="headerClasses" v-if="$slots.header || title">
      <slot name="header">
        <h2 v-if="title" class="card-title">{{ title }}</h2>
        <div class="row mt-3">
          <div class="col-sm-3">
            <img
                v-lazy="logo"
                class="rounded-circle img-raised mr-3"
                style="height: 100px; width: 100px"
            />
          </div>
          <div class="col-sm">
            <div class="d-inline d-flex float-right align-bottom w-auto">
              <p class="text-danger" style="font-size: 16px"><i
                  class="now-ui-icons objects_spaceship mr-1"/>{{ $t('LANDING.position') }}</p>
              <p v-if="subTitle" class="text-success ml-1" style="font-size: 16px">{{ subTitle }}</p>
            </div>
            <div class="d-inline d-flex float-right align-bottom" style="font-style: italic">
              <i class="now-ui-icons ui-1_calendar-60 mr-2"/>
              <div class="mr-1">
                <strong>{{ $t('LANDING.from') }}</strong> {{ from }}
              </div>
              <div class="mr-1">
                <strong>{{ $t('LANDING.to') }}</strong> {{ to }}
              </div>
            </div>
          </div>
        </div>
      </slot>
    </div>
    <div class="card-body" :class="cardBodyClasses">
      <p v-if="description" class="text-gray">{{ description }}</p>
      <p v-if="techs !== undefined" class="text-info mt-3 mb-3">
        <i class="now-ui-icons tech_controller-modern"/> {{$t('LANDING.technologies')}}
      </p>
      <slot></slot>
      <template v-for="tech in techs">
        <ul v-bind:key="tech" style="list-style-type: none;" class="list-group">
          <li class="list-group-item" style="border: none">
            <i class="now-ui-icons ui-1_send"/> {{tech}}
          </li>
        </ul>
      </template>
    </div>

    <slot name="raw-content"></slot>

    <hr/>
    <div v-if="workingAddress !== undefined" class="card-footer">
      <p class="text-black text_bold">
        <i class="now-ui-icons location_map-big"/> <strong>{{$t('LANDING.workingAddress')}}</strong> {{ workingAddress }}</p>
    </div>
  </div>
</template>
<script>
export default {
  name: "card",
  props: {
    id: String,
    type: String,
    title: String,
    subTitle: String,
    category: String,
    color: String,
    description: String,
    techs:[],
    from: String,
    to: String,
    logo: String,
    workingAddress: String,
    noFooterLine: Boolean,
    plain: Boolean,
    raised: Boolean,
    cardBodyClasses: [String, Object, Array],
    headerClasses: [String, Object, Array],
  },
};
</script>
<style></style>
