/*!

 =========================================================
 * Vue Now UI Kit - v1.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/now-ui-kit
 * Copyright 2019 Creative Tim (http://www.creative-tim.com)

 * Designed by www.invisionapp.com Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

 */
import Vue from 'vue';
import App from './App.vue';
// You can change this import to `import router from './starterRouter'` to quickly start development from a blank layout.
import router from './router';
import NowUiKit from './plugins/now-ui-kit';
import VueI18n from "vue-i18n";
import en_messages from './locales/en';
import vn_messages from  './locales/vn';
import store from './store';
import '../node_modules/timeline-vuejs/dist/timeline-vuejs.css'

Vue.config.productionTip = true;
Vue.config.devtools = true

Vue.use(VueI18n)
Vue.use(NowUiKit);

const i18n = new VueI18n({
  locale: localStorage.getItem("APP_LANGUAGE") || "en",
  debug: true,
  messages: {
    en: en_messages,
    vn: vn_messages
  },
});

new Vue({
  router,
  i18n,
  VueI18n,
  en_messages,
  vn_messages,
  store,
  render: h => h(App)
}).$mount('#app');
