import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    state:{
        langeKey: "vn",
    },
    mutations:{
        SET_LANGUAGE(state, payload){
            state.langKey = payload;
        }
    },
    actions:{

    },
    getters:{
        getLanguage(state){
            return state.langeKey
        }
    }
})