import { render, staticRenderFns } from "./Card.vue?vue&type=template&id=16be603c&"
import script from "./Card.vue?vue&type=script&lang=js&"
export * from "./Card.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports