<template>
  <div>
    <div class="page-header clear-filter page-header-small" filter-color="orange">
      <parallax
          id="pf-cover-image"
          class="page-header-image"
          style="background-image: url('img/code88galadiner2022.jpg')">
      </parallax>
      <div class="content-center">
        <div class="container">
          <h1 class="title fromtop">{{ $t('PROFILE.bestCompany') }}</h1>
        </div>
      </div>
    </div>
    <div class="section section-about-us">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">{{ $t('LANDING.title') }}</h2>
            <h5 class="description text-black">
              {{ $t('LANDING.description') }}
            </h5>
          </div>
        </div>
        <div class="separator separator-primary"></div>
        <div class="section-story-overview">
          <div class="row">
            <div class="col-md-6">
              <div
                  class="image-container image-left"
                  style="background-image: url('img/fptselffee2.jpg')"
              >
                <div class="d-block">
                  <!-- First image on the left side -->
                  <p class="blockquote blockquote-primary">
                    {{ $t('LANDING.feeling') }}
                    <br/>
                    <br/>
                    <small>-{{ $t('LANDING.great') }}</small>
                  </p>
                </div>
              </div>
              <div
                  class="image-container"
                  style="background-image: url('img/fptgraduate.jpg')"
              ></div>
              <div
                  class="image-container image-right"
                  style="background-image: url('img/code88galadiner2020.jpg')"
              ></div>
              <!-- Second image on the left side of the article -->
              <div
                  class="image-container image-right"
                  style="background-image: url('img/fptselffee.jpg')"
              ></div>

              <div
                  class="image-container image-right"
                  style="background-image: url('img/code88galadiner2022.jpg')"
              ></div>
              <div
                  class="image-container image-right"
                  style="background-image: url('img/gosell3.jpg'); height: 500px"
              ></div>
              <div
                  class="image-container image-right"
                  style="background-image: url('img/gosell1.jpg')"
              ></div>
              <div
                  class="image-container image-right"
                  style="background-image: url('img/gosell2.jpg')"
              ></div>
              <div
                  class="image-container image-right"
                  style="background-image: url('img/fpt-comeback-2.jpg')"
              ></div>
              <div
                  class="image-container image-right"
                  style="background-image: url('img/fpt-comeback-1.jpg')"
              ></div>
            </div>
            <div class="col-md-6">
              <!-- First image on the right side, above the article -->
              <div
                  class="image-container image-right"
                  style="
                  background-image: url('img/selffee.jpg');
                  background-position: center;
                  background-repeat: no-repeat;
                  background-size: auto 900px;
                  height: 445px"
              ></div>
              <h3 class="text-info">
                {{ $t('LANDING.startedHere') }}
              </h3>
              <template v-for="company in companies">
                <card v-bind:key="company.id" raised="true"
                      v-bind:title="company.companyName"
                      v-bind:sub-title="company.position"
                      v-bind:logo="company.logo"
                      v-bind:description="company.content"
                      v-bind:from="company.fromDate"
                      v-bind:to="company.toDate"
                      v-bind:workingAddress="company.address"
                      v-bind:techs="company.techs"
                >
                </card>
              </template>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ExamplesSection/>
  </div>
</template>
<script>
import {Button, FormGroupInput} from '@/components';
import Card from "../components/Cards/Card.vue";
import ExamplesSection from "@/pages/components/ExamplesSection.vue";

export default {
  name: 'landing',
  bodyClass: 'landing-page',
  components: {
    ExamplesSection,
    Card,
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput
  },
  data() {
    return {
      form: {
        firstName: '',
        email: '',
        message: ''
      },
      companies: [
        {
          id: "001",
          companyName: "FPT Software Ho Chi Minh",
          logo: "img/fptlogo.jpg",
          position: "Internship & Fresher Java Developer",
          content: this.$i18n.t('LANDING.WORKING_PLACE.FPT.INTERN.responsibility'),
          fromDate: this.$i18n.t('LANDING.WORKING_PLACE.FPT.INTERN.from'),
          toDate: this.$i18n.t('LANDING.WORKING_PLACE.FPT.INTERN.to'),
          address: this.$i18n.t('LANDING.WORKING_PLACE.FPT.INTERN.address'),
          techs: ["Java 8", "Angular 8", "MySQL", "Git"]
        },
        {
          id: "002",
          companyName: "FPT Software Ho Chi Minh",
          logo: "img/fptlogo.jpg",
          position: "Java Developer",
          content: this.$i18n.t('LANDING.WORKING_PLACE.FPT.responsibility'),
          fromDate: this.$i18n.t('LANDING.WORKING_PLACE.FPT.from'),
          toDate: this.$i18n.t('LANDING.WORKING_PLACE.FPT.to'),
          address: this.$i18n.t('LANDING.WORKING_PLACE.FPT.address'),
          techs: ["Java 8", "VueJS", "Angular 6", "MySQL", "PostgresSQL", "Git", "SVN"]
        },
        {
          id: "003",
          companyName: "CODE88",
          logo: "img/code88logo.png",
          position: "Java Web Developer",
          content: this.$i18n.t('LANDING.WORKING_PLACE.CODE88.responsibility'),
          fromDate: this.$i18n.t('LANDING.WORKING_PLACE.CODE88.from'),
          toDate: this.$i18n.t('LANDING.WORKING_PLACE.CODE88.to'),
          address: this.$i18n.t('LANDING.WORKING_PLACE.CODE88.address'),
          techs: [
            "Java 11",
            "ReactJS",
            "Spring",
            "Spring JDBC",
            "Microservices",
            "MySQL",
            "JSP",
            "SVN",
            "Jenkins"]
        },
        {
          id: "004",
          companyName: "Mediastep Software VietNam",
          logo: "img/mediasteplogo.png",
          position: "Java Backend Developer",
          content: this.$i18n.t('LANDING.WORKING_PLACE.MEDIA_STEP.responsibility'),
          fromDate: this.$i18n.t('LANDING.WORKING_PLACE.MEDIA_STEP.from'),
          toDate: this.$i18n.t('LANDING.WORKING_PLACE.MEDIA_STEP.to'),
          address: this.$i18n.t('LANDING.WORKING_PLACE.MEDIA_STEP.address'),
          techs: [
            "Java 8 & 11",
            "ReactJS",
            "Microservices",
            "Spring Boot",
            "Spring Data JPA",
            "PostgresSQL",
            "Kibana",
            "Lens",
            "Git",
            "JenKins",
            "AWS"]
        },
        {
          id: "005",
          companyName: "FPT Software Ho Chi Minh",
          logo: "img/fptlogo.jpg",
          position: "Senior Software Developer",
          content: this.$i18n.t('LANDING.WORKING_PLACE.FPT.COMEBACK.responsibility'),
          fromDate: this.$i18n.t('LANDING.WORKING_PLACE.FPT.COMEBACK.from'),
          toDate: this.$i18n.t('LANDING.WORKING_PLACE.FPT.COMEBACK.to'),
          address: this.$i18n.t('LANDING.WORKING_PLACE.FPT.COMEBACK.address'),
          techs: ["Java 27 & 11",
            "ZK Framework",
            "Microservices",
            "Oracle Database",
            "Spring Boot",
            "Mybatis Framework",
            "Kibana",
            "Git",
            "JenKins"]
        }
      ]
    };
  }
};
</script>
<style>
</style>
