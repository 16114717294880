<template>
  <div>
    <div class="page-header clear-filter" filter-color="orange">
      <parallax
          class="page-header-image"
          style="background-image: url('img/code88teambuilding2022.jpg')"
      >
      </parallax>
      <div class="content-center">
        <div class="container">
          <h1 class="title lineUp">{{$t('PROFILE.slogant')}}</h1>
          <p class="description lineUp" style="font-size: 22px">{{$t('PROFILE.subSlogant')}}</p>
        </div>
      </div>
    </div>
    <!--         <basic-elements></basic-elements>-->
    <!--     <navigation></navigation>-->
    <!--     <tabs-section></tabs-section>-->
    <!--     <progress-pagination></progress-pagination>-->
    <!--     <notifications></notifications>-->
<!--         <typography></typography>-->
    <!--     <javascript-components></javascript-components>-->
<!--         <carousel-section></carousel-section>-->
    <!--     <nucleo-icons-section></nucleo-icons-section>-->
    <!--     <signup-form></signup-form>-->
    <!--     <examples-section></examples-section>-->
    <WhatWeDo/>
    <download-section></download-section>
  </div>
</template>
<script>
import {Parallax} from "@/components";
import BasicElements from "./components/BasicElementsSection";
import Navigation from "./components/Navigation";
import TabsSection from "./components/Tabs";
import ProgressPagination from "./components/ProgressPagination";
import Notifications from "./components/Notifications";
import Typography from "./components/Typography";
import JavascriptComponents from "./components/JavascriptComponents";
import CarouselSection from "./components/CarouselSection";
import NucleoIconsSection from "./components/NucleoIconsSection";
import SignupForm from "./components/SignupForm";
import ExamplesSection from "./components/ExamplesSection";
import DownloadSection from "./components/DownloadSection";

import WhatWeDo from "@/pages/components/WhatWeDo";

export default {
  name: "index",
  bodyClass: "index-page",
  components: {
    Parallax,
    BasicElements,
    Navigation,
    TabsSection,
    ProgressPagination,
    Notifications,
    Typography,
    JavascriptComponents,
    CarouselSection,
    NucleoIconsSection,
    SignupForm,
    ExamplesSection,
    DownloadSection,
    WhatWeDo,
  },
};
</script>
<style>

</style>
