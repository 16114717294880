<template>
  <navbar
      position="fixed"
      type="primary"
      :transparent="transparent"
      :color-on-scroll="colorOnScroll"
      menu-classes="ml-auto"
  >
    <template>
      <router-link v-popover:popover1 class="navbar-brand" to="/">
        {{ $t("MAIN_PAGE.title") }}
      </router-link>
    </template>
    <template slot="navbar-menu">
      <li class="nav-item">
        <router-link to="/profile" class="nav-link">
          <i class="now-ui-icons users_single-02"></i> {{ $t('MENU.profile') }}
        </router-link>
      </li>
      <li class="nav-item">
        <router-link to="/landing" class="nav-link">
          <i class="now-ui-icons tech_tv"></i> {{ $t('MENU.experience_knowledge') }}
        </router-link>
      </li>
      <li class="nav-item">
        <router-link to="/experience-and-certification" class="nav-link">
          <i class="now-ui-icons education_paper"></i> {{ $t('MENU.education') }}
        </router-link>
      </li>
      <template>
        <DropDown :title="$t('LANGUAGES.' + `${language}`)"
                  icon="now-ui-icons objects_planet"
                  position="right">
        </DropDown>
      </template>
    </template>
  </navbar>
</template>

<script>
import {DropDown, Navbar, NavLink} from "@/components";
import {Popover} from "element-ui";

export default {
  name: "main-navbar",
  props: {
    transparent: Boolean,
    colorOnScroll: Number,
  },
  components: {
    DropDown,
    Navbar,
    NavLink,
    [Popover.name]: Popover,
  },

  computed: {
    language(){
      return this.$store.state.langeKey;
    }
  },

  watch:{
    language: function (val, oldVal){
      console.log(val);
      console.log(oldVal);
      this.resetMenu();
    }
  },

  data() {
    return {
      links: [
        {
          id: 1,
          to: '/profile',
          label: this.$i18n.t('MENU.profile'),
          icon: 'now-ui-icons users_single-02',
        },
        {
          id: 2,
          to: '/landing',
          label: this.$i18n.t('MENU.experience_knowledge'),
          icon: 'now-ui-icons tech_tv',
        },
        {
          id: 2,
          to: '/experience-and-certification',
          label: this.$i18n.t('MENU.education'),
          icon: 'now-ui-icons education_paper',
        },
        // {
        //   id: 3,
        //   to: '/contact',
        //   label: this.$i18n.t('MENU.contact'),
        //   icon: 'now-ui-icons ui-1_send'
        // }
      ]
    }
  },

  methods: {
    resetMenu() {
      this.links = [];
      this.links.push({
            id: 1,
            to: '/profile',
            label: this.$i18n.t('MENU.profile'),
            icon: 'now-ui-icons users_single-02',
          },
          {
            id: 2,
            to: '/landing',
            label: this.$i18n.t('MENU.experience_knowledge'),
            icon: 'now-ui-icons tech_tv',
          },
          {
            id: 2,
            to: '/experience-and-certification',
            label: this.$i18n.t('MENU.education'),
            icon: 'now-ui-icons education_paper',
          },)
    }
  }
};
</script>

<style scoped></style>
