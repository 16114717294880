<template>
  <div>
    <div class="page-header clear-filter page-header-small" filter-color="orange">
      <parallax
          class="page-header-image"
          style="background-image: url('img/certificate-vs-certification.jpg')"
      >
      </parallax>
      <div class="content-center">
        <div class="container">
          <h1 class="title fromtop">{{ $t('LANDING2.iHad') }}</h1>
        </div>
      </div>
    </div>
    <div class="container">
      <h2 class="title">{{ $t('LANDING2.certificate') }}</h2>
      <h5 class="description text-black">
        {{ $t('LANDING2.description') }}
      </h5>
      <div class="row mb-5">
        <div class="col-sm-3 mr-auto">
          <img
              v-lazy="'img/fptfresheraccademy.png'"
          />
        </div>
        <div class="col-sm-3 mr-auto">
          <img
              v-lazy="'img/outsystemlogo.png'"
          />
        </div>
        <div class="col-sm-3 mr-auto">
          <img
              v-lazy="'img/Pinnacle_Logo.jpeg'"
              alt="Thumbnail Image"
          />
        </div>
      </div>
      <Timeline
          :timeline-items="certificates"
          :message-when-no-items="messageWhenNoItems"
          :show-day-and-month="true"
          :uniqueYear="true"
          color-dots="#20B2AA"
          :date-locale="getLanguage"
          order="desc"/>
    </div>
    <div class="container">
      <h2 class="title">{{ $t('LANDING2.awarded') }}</h2>
      <h5 class="description text-black">
        {{ $t('LANDING2.description2') }}
      </h5>
      <Timeline
          :timeline-items="awarded"
          :message-when-no-items="messageWhenNoItems"
          :show-day-and-month="true"
          :uniqueYear="true"
          color-dots="#00BFFF"
          :date-locale="getLanguage"
          order="desc"/>
      <div class="separator separator-primary"></div>
      <div class="section-story-overview">
        <div class="row">
          <div class="col-md-6">
            <div
                class="image-container image-right"
                style="background-image: url('img/code88internalfootballchampion.jpg')"
            ></div>
            <!-- Second image on the left side of the article -->

          </div>
          <div class="col-md-6">
            <div
                class="image-container image-left"
                style="background-image: url('img/code88winerkingofthenight.jpg')"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {Button, FormGroupInput} from '@/components';
import Timeline from "timeline-vuejs";
import CertificateCard from "@/components/Cards/CertificateCard.vue";

export default {
  name: 'landing',
  bodyClass: 'landing-page',
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
    [Timeline.name]: Timeline,
    CertificateCard
  },

  computed: {
    getLanguage() {
      return localStorage.getItem("APP_LANGUAGE");
    }
  },

  data() {
    return {
      form: {
        firstName: '',
        email: '',
        message: ''
      },
      messageWhenNoItems: 'There arent items',
      awarded: [
        {
          title: "FPT Software Ho Chi Minh",
          description: "2nd Prize - Spring Internal Football Champion 2019",
          from: new Date(2019, 11, 15),
          showDayAndMonth: false
        },
        {
          title: "CODE88",
          description: "1st - King Of The Night",
          from: new Date(2022, 1, 15),
          showDayAndMonth: false
        },
        {
          title: "CODE88",
          description: "2nd Prize - Summer Internal Football Champion 2022",
          from: new Date(2022, 3, 15),
          showDayAndMonth: false
        },
      ],
      certificates: [
        {
          title: "FPT Software Ho Chi Minh",
          description: "CERTIFICATE OF COMPLETION...has successfully completed the FRESHER TRAINING course by Fresher Academy",
          from: new Date(2019, 6, 15),
          showDayAndMonth: true
        },
        {
          title: "outsystems",
          description: "has successfully completed the certificate process to be officially recognized as Associate Web Developer",
          from: new Date(2020, 7, 1),
          showDayAndMonth: true
        },
        {
          title: "PINNACLE",
          description: "CERTIFICATE OF COMPLETION...has successfully completed the Malta Anti Money Laundering & Combatting the Financing of Terrorism",
          from: new Date(2021, 5, 29),
          showDayAndMonth: true
        },
        {
          title: "PINNACLE",
          description: "CERTIFICATE OF COMPLETION... has successfully completed the Swedish Gambling Compliance Responsible Gaming Course",
          from: new Date(2021, 5, 29),
          showDayAndMonth: true
        },
        {
          title: "PINNACLE",
          description: "CERTIFICATE OF COMPLETION...Cybersecurity - Suffering The Web",
          from: new Date(2021, 5, 29),
          showDayAndMonth: true
        }
      ]
    };
  }
};
</script>
