<template>
  <div
      class="section section-download"
      id="#download-section"
      data-background-color="black"
  >
    <div class="container">
      <div class="row justify-content-md-center">
        <div class="text-center col-md-12 col-lg-8">
          <h2 class="title" style="color: orange">{{ $t('DOWNLOAD_SECTION.inviteTitle') }}</h2>
          <h5 class="description">
            {{ $t('DOWNLOAD_SECTION.invite') }}
          </h5>
        </div>
      </div>
      <br/>
      <br/>
      <div class="row text-center mt-5">
        <div class="col-md-8 ml-auto mr-auto">
          <h2 style="color: greenyellow">{{ $t('DOWNLOAD_SECTION.objective') }}</h2>
          <h5 class="description">
            {{ $t('DOWNLOAD_SECTION.objectiveTitle') }}
          </h5>
        </div>
      </div>
      <br/>
      <br/>
      <div class="row justify-content-md-center sharing-area text-center">
        <div class="text-center col-md-12 col-lg-8">
          <h3>{{$t('DOWNLOAD_SECTION.canUse')}}</h3>
        </div>
        <div class="text-center col-md-12 col-lg-8">
          <div class="row">
            <div class="col-sm-3 image-container">
              <img
                  v-lazy="'img/java-la-gi.jpg'"
                  alt="Java"
                  class="rounded"
              />
            </div>
            <div class="col-sm-3 image-container">
              <img
                  v-lazy="'img/csharpelogo.jpg'"
                  alt="C# .NET"
                  class="rounded"
              />
            </div>
            <div class="col-sm-3 image-container">
              <img
                  v-lazy="'img/mysql-logo.png'"
                  alt="MySQL"
                  class="rounded"
              />
            </div>
            <div class="col-sm-3 image-container">
              <img
                  v-lazy="'img/postgres-1.png'"
                  alt="PostgresSQL"
                  class="rounded"
              />
            </div>
            <div class="col-sm-3 image-container">
              <img
                  v-lazy="'img/jenkins_logo.png'"
                  alt="Jenkin"
                  class="rounded"
              />
            </div>
            <div class="col-sm-3 image-container">
              <img
                  v-lazy="'img/heroku.png'"
                  alt="Heroku"
                  class="rounded"
              />
            </div>
            <div class="col-sm-3 image-container">
              <img
                  v-lazy="'img/Logo-Vuejs.png'"
                  alt="VueJS"
                  class="rounded"
              />
            </div>
            <div class="col-sm-3 image-container">
              <img
                  v-lazy="'img/reactjs.png'"
                  alt="ReactJS"
                  class="rounded"
              />
            </div>
            <div class="col-sm-3 image-container">
              <img
                  v-lazy="'img/GitHub-logo.png'"
                  alt="GitHub"
                  class="rounded"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style>
</style>
