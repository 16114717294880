<template>
  <footer
      class="footer"
      :class="{ [`footer-${type}`]: type }"
      :data-background-color="backgroundColor"
  >
    <div class="container">
      <nav>
        <ul>
          <li>
            <a>duyvt7@gmail.com</a>
          </li>
          <li>
            <a> 0382763906 </a>
          </li>
          <li>
            <a>Mr. Duy</a>
          </li>
        </ul>
      </nav>
      <div class="copyright">
        &copy; {{ year }}, {{ $t('FOOTER.designedBy') }} {{ $t("PROFILE.myName") }} - {{ $t('PROFILE.address') }}
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  props: {
    backgroundColor: String,
    type: String,
  },
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>
<style></style>
